import { render, staticRenderFns } from "./AdvertiserCampaignDetails.vue?vue&type=template&id=64a96647"
import script from "./AdvertiserCampaignDetails.vue?vue&type=script&lang=ts"
export * from "./AdvertiserCampaignDetails.vue?vue&type=script&lang=ts"
import style0 from "./AdvertiserCampaignDetails.vue?vue&type=style&index=0&id=64a96647&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports