
import { Component, Prop, Vue } from 'vue-property-decorator';
import IButton from '@/ui-components/IButton/IButton.vue';
import { AdvertiserCampaign, StoreCampaign } from '@/types/campaigns';

@Component({
  name: 'Request',
  components: {
    IButton,
  },
})
export default class Request extends Vue {
  @Prop({ type: Object }) public campaign!: AdvertiserCampaign;

}
