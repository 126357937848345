
import { Component, Vue } from 'vue-property-decorator';
import Media from '@/ui-components/Media/Media.vue';
import AdvertiserCampaignDetails from '@/ui-components/AdvertiserCampaignDetails/AdvertiserCampaignDetails.vue';
import CampaignStatus from '@/ui-components/CampaignStatus/CampaignStatus.vue';
import CampaignMedia from '@/ui-components/CampaignMedia/CampaignMedia.vue';
import CampaignRevenue from '@/ui-components/CampaignRevenue/CampaignRevenue.vue';
import HeaderTitle from './components/HeaderTitle.vue';
import Request from './components/Request.vue';
import { Action, Getter } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { AdvertiserCampaign } from '@/types/campaigns';
import { GetCampaignById } from '@/store/modules/advertiser-module/types';
import { loadCampaignByIdAndUserId } from '@/actions/campaigns/actions';
import { AuthorizedUser } from '@/types/users';
import { extractMyCampaignData } from '@/utils/campaigns';
import { LocationData } from '@/types/locations';

@Component({
  name: 'CampaignDetailsPage',
  components: {
    Request,
    Media,
    HeaderTitle,
    CampaignStatus,
    CampaignRevenue,
    AdvertiserCampaignDetails,
    CampaignMedia,
  },
})
export default class CampaignDetailsPage extends Vue {
  @Getter("userInfo", { namespace: namespaces.AuthModule })
  public userInfo!: AuthorizedUser;

  @Getter("locations", { namespace: namespaces.CommonModule })
  public locations!: LocationData[];

  @Action('getCampaignById', { namespace: namespaces.AdvertiserModule })
  public getCampaignById!: GetCampaignById;

  public campaign: AdvertiserCampaign | null = null;

  public get campaignId() {
    return this.$route.params.id;
  }

  public get hasRequest() {
    return false;
  }

  public async getCampaignData() {
    this.campaign = await this.getCampaignById(this.campaignId);
    if (!this.campaign && this.campaignId) {
      const campaign = await loadCampaignByIdAndUserId(this.campaignId, this.userInfo.UID)
      this.campaign = extractMyCampaignData(campaign, this.locations, this.userInfo);
    }
    if (!this.campaign || !this.campaignId) {
      this.$router.go(-1);
    }
  }

  public created() {
    this.getCampaignData();
  }
}
