
import { Component, Prop, Vue } from 'vue-property-decorator';
import IButton from '@/ui-components/IButton/IButton.vue';
import { AdvertiserCampaign, Campaign } from '@/types/campaigns';
import { getAdvertiserCampaignCategory } from '@/utils/campaigns';

@Component({
  name: 'HeaderTitle',
  components: {
    IButton,
  },
})
export default class HeaderTitle extends Vue {
  @Prop({ type: Object }) public campaign!: AdvertiserCampaign;

  public get campaignCategory() {
    return  getAdvertiserCampaignCategory(this.campaign);
  }

  public back() {
    return this.$router.go(-1);
  }
}
